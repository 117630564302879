<template>
  <div>
    <div class="header-background">
      <div class="qr-code-box">
        <div class="system-type">
          <div class="android" @click="systemType='android'">
            <img v-if="systemType==='android'" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625807621940.png" alt="">
            <img v-else src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626252998330.png" alt="">
          </div>
          <div class="iphone" @click="systemType='iphone'">
            <img v-if="systemType==='android'" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625807659603.png" alt="">
            <img v-else src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626253059619.png" alt="">
          </div>
        </div>
        <div class="qr-code-img-box">
          <div class="qr-code">
            <!-- <img v-show="systemType==='android'" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251728619.png" alt="">
            <img v-show="systemType==='iphone'" src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251938569.png" alt=""> -->
          </div>
          <div class="qr-code-text">扫一扫，立即下载</div>
        </div>
      </div>
    </div>
    <div class="other-platforms-box w">
      <div class="other-platforms">
        <div class="other-platforms-top">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251680546.png" alt="">
        </div>
        <div class="other-platforms-bottom">关注微信公众号</div>
      </div>
      <div class="other-platforms">
        <div class="other-platforms-top">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251661828.png" alt="">
        </div>
        <div class="other-platforms-bottom">关注微信小程序</div>
      </div>
      <div class="other-platforms">
        <div class="other-platforms-top">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251643058.png" alt="">
        </div>
        <div class="other-platforms-bottom">关注墨鱼旅行微博</div>
      </div>
      <div class="other-platforms">
        <div class="other-platforms-top"><img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251621305.png" alt=""></div>
        <div class="other-platforms-bottom">关注墨鱼旅行知乎</div>
      </div>
      <div class="other-platforms">
        <div class="other-platforms-top">
          <img src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251568049.png" alt="">
        </div>
        <div class="other-platforms-bottom">关注墨鱼旅行小红书</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemType: "android",
    };
  },
};
</script>

<style scoped lang="scss">
.header-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 720px;
  background: url(https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625646368673.png)
    no-repeat;
  .qr-code-box {
    display: flex;
    justify-content: space-between;
    width: 646px;
    height: 254px;
    .system-type {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .qr-code-img-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .qr-code {
        width: 204px;
        height: 204px;
        background-image: url(https://oss.cuttlefish.vip/app/qrCode.png);
        background-size: 100% 100%;
      }

      .qr-code-text {
        font-size: 22px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #ffffff;
      }
    }
  }
}
.other-platforms-box {
  display: flex;
  justify-content: space-between;
  margin-top: 89px;
  height: 138px;
  .other-platforms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .other-platforms-top {
      width: 90px;
      height: 90px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .other-platforms-bottom {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>